body {
  background: linear-gradient(180deg, #9486b0 0%, #594b75 100%);
}

.App {
  height: auto;
  min-height: 100vh;
  font-family: 'Roboto Mono', monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  max-width: 980px;
  margin: 0 auto;
  font-weight: normal;
  padding: 1rem;

  @media (min-width: 576px) {
    padding: 2rem 4rem;
  }
}

.container {
  flex-direction: column;

  @media (min-width: 640px) {
    display: flex;
    flex-direction: row;
  }
}
